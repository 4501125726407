import React from 'react';
import Colors from '../constants/ColorConstants';

const StarIcon = ({
  size = 24, 
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props 
}) => (
  <svg viewBox="0 0 1024 1024" p-id="2421" width={size} height={size} {...props}>
    <path d="M512.009505 25.054894l158.199417 320.580987 353.791078 51.421464L767.995248 646.579761l60.432101 352.365345-316.417844-166.354615-316.436854 166.354615 60.432101-352.365345L0 397.057345l353.791078-51.421464z" fill="#EFCE4A" p-id="2422"></path>
    </svg>
);

export default StarIcon