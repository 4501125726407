import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
} from 'react-router-dom';
import PagePathConstants, { updateRouterConstants } from './constants/router/PagePathConstants';
import * as routerActionCreator from './actions/routerActionCreator';
import * as baseActionCreator from './actions/baseActionCreator';
import * as RouterSelectors from './selectors/RouterSelectors'
import EntrancePage from './pages/EntrancePage';
import CheckoutPage from './pages/CheckoutPage';
import OrderStatusPage from './pages/OrderStatusPage';
import GlobalAlert from './components/common/GlobalAlert';
import './App.css';
import { setAPIBaseUrl } from './apis/API';
import * as ResponsivePageUtils from './utils/ResponsivePageUtils';
import Loading from './components/common/LoadingIndicator';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
    }
    this.toggleLoading = this.toggleLoading.bind(this);
    this.fetchRouterConfig = this.fetchRouterConfig.bind(this);
    this.fetchBaseConfig = this.fetchBaseConfig.bind(this);
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  fetchRouterConfig() {
    const {routerActions} = this.props;
    this.toggleLoading();
    routerActions && routerActions.fetchRouterConfig()
    .then(() => {
      this.toggleLoading();
    })
    .catch(this.toggleLoading);
  }

  componentDidMount() {
    if (window.location.host.indexOf('localhost') < 0) {
      const baseUrl = window.location.protocol + "//" + window.location.host;
      setAPIBaseUrl(baseUrl);
    }
    this.fetchRouterConfig();
    this.fetchBaseConfig();
  }

  fetchBaseConfig() {
    const {baseActions} = this.props;
    baseActions && baseActions.fetchBaseConfig('bannerArr,adPic,partnerEmail,coupon,couponCode,couponRepeated,freeShippingAmount,shipArr,brandLogo,aboutUs,provacyPolicy,shippingPolicy,companyAddress,phone,pixelId')
    .then(data => {
      console.log(data);
      if (data && data.pixelId) {
        const pixelId = data.pixelId;
        let head = document.querySelector('head');
          let script = document.createElement('script');
          script.type = "text/javascript";
          script.innerHTML = `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
              n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');`

          head.appendChild(script);

          let noscript = document.createElement('noscript');
          noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />`;
          head.appendChild(noscript);
      }
    });
}

  render() {
    // const isMobile = ResponsivePageUtils.isMobileScreen();
    // const appClassName = isMobile ? 'is_mobile App' : 'App';
    const {showLoading} = this.state;
    console.log(showLoading)
    if (showLoading) return <Loading />;

    return (
      <HashRouter>
        <div className="tw-h-full">
          <GlobalAlert />
          <Switch>
            <Route path={PagePathConstants.CHECKOUT_PAGE} component={CheckoutPage} />
            
            <Route path={PagePathConstants.ENTRANCE_PAGE} component={EntrancePage} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}


function mapStateToProps(state) {
  return {
    routerConfig: RouterSelectors.selectRouterConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActionCreator, dispatch),
    baseActions: bindActionCreators(baseActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);