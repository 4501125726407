export const BASE_URL = window.location.protocol + "//" + window.location.host;
export default {
    UPLOAD: '/api/common/upload',
    ORDER: '/api/product/pay',
    PRODUCT_LIST: '/api/product/productList',
    PRODUCT_DETAIL: '/api/product/getProduct',

    LOGIN: '/api/user/login',
    REGISTER: '/api/user/register',
    LOGOUT: '/api/user/logout',
    BASE_CONFIG: '/api/product/getDeploy',
    CATEGORY_LIST: '/api/product/categoryList',
    CATEGORY_DETAIL: '/api/product/getCategory',
    TAG_LIST: '/api/product/labelList',
};
