import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import PagePathConstants from '../constants/router/PagePathConstants';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import CartPage from './CartPage';
import HeaderNav from '../components/common/headerNav';
import ProductDetailPage from './ProductDetailPage';
import ProductCategoryPage from './ProductCategoryPage';
import OrderStatusPage from './OrderStatusPage';
import AboutUsPage from './AboutUsPage';
import ProvacyPolicyPage from './ProvacyPolicyPage';
import ShippingPolicyPage from './ShippingPolicyPage';
import Footer from '../components/common/footer';
import * as ShoppingCartSelectors from '../selectors/ShoppingCartSelectors';

class EntrancePage extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
    }

    render() {
        const {location} = this.props;
        return (
            <div className='tw-w-full tw-h-full '>
                <div className='tw-w-full tw-relative tw-z-[100]'>
                    {/* <div className='tw-w-full tw-h-[42px] tw-flex tw-items-center tw-justify-center'>
                        ✈✈✈Frete grátis a partir de 119 $BRL Shop now
                    </div> */}
                    {/* <div className='tw-w-full tw-h-[66px] tw-px-[100px] tw-flex tw-items-center tw-justify-end'>
                        <div className='tw-border tw-w-[116px] tw-h-full tw-flex tw-justify-between tw-items-center'>
                        </div>
                    </div> */}
                    <HeaderNav {...this.props} />
                </div>
                <div className="tw-w-full router_container scrollbar-hidden">
                    <Switch>
                        <Route path={PagePathConstants.PRODUCT_DETAIL} component={ProductDetailPage} />
                        <Route path={PagePathConstants.PRODUCT_CATEGORY} component={ProductCategoryPage} />
                        <Route path={PagePathConstants.HOME_PAGE} component={HomePage} />
                        <Route path={PagePathConstants.LOGIN_PAGE} component={LoginPage} />
                        <Route path={PagePathConstants.REGISTER_PAGE} component={RegisterPage} />
                        <Route path={PagePathConstants.SHOPPING_CART} component={CartPage} />
                        <Route path={PagePathConstants.ORDER_CALLBACK} component={OrderStatusPage} />
                        <Route path={PagePathConstants.ABOUT_US} component={AboutUsPage} />
                        <Route path={PagePathConstants.SHIPPING_POLICY} component={ShippingPolicyPage} />
                        <Route path={PagePathConstants.PROVACY_POLICY} component={ProvacyPolicyPage} />
                        <Redirect exact to={`${PagePathConstants.HOME_PAGE}${location.search}`} />
                    </Switch>
                </div>
                <Footer {...this.props}/>

            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
        shoppingCartArr: ShoppingCartSelectors.selectShoppingCartData(state),
    };
}

export default connect(mapStateToProps, null)(EntrancePage);

