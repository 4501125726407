import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserIcon from '../../../icons/UserIcon';
import ShoppingCartIcon from '../../../icons/ShoppingCartIcon';
import DrawerIcon from '../../../icons/DrawerIcon';
import MobileDetectUtil from '../../../utils/MobileDetectUtil';
import classNames from 'classnames';
import PagePathConstants from '../../../constants/router/PagePathConstants';
import * as categoryActionCreator from '../../../actions/categoryActionCreator';
import * as authActionCreator from '../../../actions/authActionCreator';
import * as UserSelectors from '../../../selectors/UserSelectors';
import * as ShoppingCartSelectors from '../../../selectors/ShoppingCartSelectors';
import * as BaseConfigSelectors from '../../../selectors/BaseConfigSelectors';
import { LOGIN_CONSTANTS } from '../../../constants/store/StoreStateConstants';
import { BASE_URL } from '../../../constants/api/ApiEndpointConstants';

class HeaderNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            activeIndex: 0,
            activeRoute: '',
            showUserDropdown: false,
            position: 'relative',
            categoryList: [],
            // shoppingCartArr: ProductHelper.getShoppingCartData()
        }
        this.toggleMenu = this.toggleMenu.bind(this);
        this.selectMenu = this.selectMenu.bind(this);
        this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
        this.routeToLoginPage = this.routeToLoginPage.bind(this);
        this.routeToRegisterPage = this.routeToRegisterPage.bind(this);
        this.routeToCartPage = this.routeToCartPage.bind(this);
        this.routerToCategoryPage = this.routerToCategoryPage.bind(this);
        this.routeToHomePage = this.routeToHomePage.bind(this);
        this.renderHeaderPostition = this.renderHeaderPostition.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }
    componentDidMount() {
        const { location } = this.props;
        this.setState({ activeRoute: location && location.pathname });
        this.renderHeaderPostition();
        this.fetchCategoryList();

    }

    handleLogout() {
        const { authActions, history, location } = this.props;
        authActions && authActions.logout()
            .then(() => {
                history && history.push({pathname: PagePathConstants.LOGIN_PAGE, search: location.search});
            })   
    }

    fetchCategoryList() {
        const { categoryActions } = this.props;
        categoryActions && categoryActions.fetchProductCategoryList()
            .then((categoryList) => {
                this.setState({ categoryList });
            })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({ activeRoute: nextProps.location && nextProps.location.pathname });
            this.renderHeaderPostition(nextProps);
        }
    }

    renderHeaderPostition(props) {
        const { location } = (props || this.props);
        const absolutePathArr = [PagePathConstants.PRODUCT_CATEGORY.replace('/:id', ''), PagePathConstants.ABOUT_US, PagePathConstants.PROVACY_POLICY, PagePathConstants.SHIPPING_POLICY];
        if (absolutePathArr.indexOf(location.pathname) >= 0) {
            this.setState({ position: 'absolute' });
        } else {
            this.setState({ position: 'relative' });
        }
    }

    toggleMenu() {
        this.setState({ showMenu: !this.state.showMenu });
    }

    toggleUserDropdown() {
        this.setState({ showUserDropdown: !this.state.showUserDropdown });
    }

    selectMenu(index, e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        this.setState({ activeIndex: index });
    }

    routeToLoginPage() {
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.LOGIN_PAGE, search: location.search});
        this.toggleUserDropdown();
        MobileDetectUtil.isMobile() && this.toggleMenu();
    }

    routeToRegisterPage() {
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.REGISTER_PAGE, search: location.search});
        this.toggleUserDropdown();
        MobileDetectUtil.isMobile() && this.toggleMenu();
    }

    routeToCartPage() {
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.SHOPPING_CART, search: location.search});
    }

    routerToCategoryPage(item, e) {
        e && e.stopPropagation();
        const { history, categoryActions, location } = this.props;
        const url = PagePathConstants.PRODUCT_CATEGORY?.replace(':id', item.id);
        categoryActions.selectCurrentCategory(item);
        history && history.replace({pathname: url, search: location.search});
        this.toggleMenu();
    }

    routeToHomePage() {
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.ENTRANCE_PAGE, search: location.search});
    }

    render() {
        const { showMenu, activeIndex, activeRoute, showUserDropdown, position, categoryList } = this.state;
        const { loginStatus, shoppingCartArr, baseConfig } = this.props;
        let shoppingCartNum = 0;
        shoppingCartArr && shoppingCartArr.map(item => {
            shoppingCartNum += parseInt(item.quantity)
        });
        const brandLogo = (baseConfig && baseConfig.brandLogo) ? (BASE_URL + baseConfig.brandLogo) : '';
        return (
            <React.Fragment>
                <div className={`phone:tw-hidden tw-w-[1130px] tw-h-[90px] tw-flex tw-justify-between tw-items-center  tw-bg-white tw-px-[30px] tw-flex tw-justify-between tw-items-center lora-font ${position === 'absolute' ? 'tw-absolute tw-top-[30px] tw-left-[50%] tw-ml-[-565px]' : 'tw-mt-0 tw-mx-auto'}`}>
                    <div className='tw-flex tw-items-center tw-text-[14px]'>
                        <div onClick={this.routeToHomePage}>
                            <div className='tw-w-[160px] tw-h-[90px] tw-mr-[30px]' style={{backgroundImage: `url(${brandLogo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                        </div>
                        {categoryList.map((item, index) => {
                            return (<div key={item.id} className='tw-mr-[30px]' onClick={(e) => this.routerToCategoryPage(item, e)}>{item.title?.toUpperCase()}</div>)
                        })}
                    </div>
                    <div className='tw-flex tw-items-center'>
                        {/* <SearchIcon className="tw-mr-[20px]"/> */}
                        <div className='tw-relative'>
                            <UserIcon className="tw-mr-[20px]" onClick={this.toggleUserDropdown} />
                            {loginStatus === LOGIN_CONSTANTS.LOGGED_OUT && <div className={classNames('tw-absolute tw-top-[35px] tw-py-[5px] tw-left-[50%] tw-ml-[-109px] tw-w-[218px] tw-h-[100px] tw-rounded-[20px] tw-bg-white tw-text-body-text-1-translucence tw-text-[14px] tw-shadow-md tw-hidden', { '!tw-block': showUserDropdown })} >
                                <div className='tw-px-[20px] tw-py-[12px]' onClick={this.routeToLoginPage}>Sign in</div>
                                <div className='tw-px-[20px] tw-py-[12px]' onClick={this.routeToRegisterPage}>Create your account here</div>
                            </div>}
                            {loginStatus === LOGIN_CONSTANTS.LOGGED_IN && <div className={classNames('tw-absolute tw-top-[35px] tw-py-[5px] tw-left-[50%] tw-ml-[-109px] tw-w-[218px] tw-h-[50px] tw-rounded-[20px] tw-bg-white tw-text-body-text-1-translucence tw-text-[14px] tw-shadow-md tw-hidden', { '!tw-block': showUserDropdown })} >
                                <div className='tw-px-[20px] tw-py-[12px]' onClick={this.handleLogout}>Logout</div>
                            </div>}
                        </div>

                        <div className='tw-relative'>
                            {shoppingCartNum > 0 && <div className='tw-absolute tw-min-w-[23px] tw-min-h-[23px] tw-px-[2px] tw-rounded-full tw-bg-[#222] tw-text-white tw-text-[12px] tw-flex tw-justify-center tw-items-center tw-bottom-[12px] tw-right-[8px]'>{shoppingCartNum}</div>}
                            <ShoppingCartIcon className="tw-mr-[20px]" onClick={this.routeToCartPage} />
                        </div>
                    </div>
                </div>
                {MobileDetectUtil.isMobile() && <React.Fragment>
                    <div className='tw-flex tw-justify-between tw-items-center lora-font'>
                        <div className='tw-w-[22%] tw-pl-[20px] tw-flex tw-justify-start tw-items-center'><DrawerIcon onClick={this.toggleMenu} /></div>
                        <div className='tw-w-[56%] tw-flex tw-justify-center tw-items-center'><img className='tw-w-[120px] tw-h-[67px] ' src='https://daebaby.com/wp-content/uploads/2023/09/LOGO.jpg' alt='brand_logo' /></div>
                        <div className='tw-w-[22%] tw-pr-[20px] tw-flex tw-justify-end tw-items-center'>
                            <div className='tw-relative'>
                                {shoppingCartNum > 0 && <div className='tw-absolute tw-min-w-[23px] tw-min-h-[23px] tw-px-[2px] tw-rounded-full tw-bg-[#222] tw-text-white tw-text-[12px] tw-flex tw-justify-center tw-items-center tw-bottom-[12px] tw-right-[8px]'>{shoppingCartNum}</div>}
                                <ShoppingCartIcon className="tw-mr-[20px]" onClick={this.routeToCartPage} />
                            </div>
                        </div>
                    </div>
                    {showMenu && <div className='tw-bg-[rgba(0,0,0,0.7)] tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full lora-font tw-text-[14px]' onClick={this.toggleMenu}>
                        <div className='tw-w-[75%] tw-h-full tw-bg-white drawer-animate'>
                            <div className='tw-w-full tw-h-[52px] tw-flex tw-items-center'>
                                <div className={`tw-w-[50%] tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-[#f6f6f6] ${activeIndex === 0 ? 'active-tab' : ''}`} onClick={(e) => this.selectMenu(0, e)}>MENU</div>
                                <div className={`tw-w-[50%] tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-[#f6f6f6] ${activeIndex === 1 ? 'active-tab' : ''}`} onClick={(e) => this.selectMenu(1, e)}>CATEGORIES</div>
                            </div>
                            {activeIndex === 0 && (<div className='tw-text-[14px]'>
                                <div className={`tw-w-full tw-h-[50px] tw-py-[5px] tw-px-[20px] tw-flex tw-items-center tw-border-b ${activeRoute === '/home' ? 'active-router' : ''}`} onClick={this.routeToHomePage}>HOME</div>
                                {/* <div className={`tw-w-full tw-h-[50px] tw-py-[5px] tw-px-[20px] tw-flex tw-items-center tw-border-b ${activeRoute === '/shop' ? 'active-router' : ''}`}>SHOP</div> */}
                                {loginStatus === LOGIN_CONSTANTS.LOGGED_OUT && <div className={`tw-w-full tw-h-[50px] tw-py-[5px] tw-px-[20px] tw-flex tw-items-center tw-border-b ${activeRoute === '/login' ? 'active-router' : ''}`} onClick={this.routeToLoginPage}><UserIcon className="tw-mr-[15px]" />LOGIN/REGISTER</div>}
                                {loginStatus === LOGIN_CONSTANTS.LOGGED_IN && <div className={`tw-w-full tw-h-[50px] tw-py-[5px] tw-px-[20px] tw-flex tw-items-center tw-border-b`} onClick={this.handleLogout}><UserIcon className="tw-mr-[15px]" />LOGOUT</div>}
                            </div>)}
                            {activeIndex === 1 && (<div className='tw-text-[14px]'>
                                {categoryList.map((item, index) => {
                                    const url = PagePathConstants.PRODUCT_CATEGORY?.replace(':id', item.id);
                                    return (<div key={item.id} className={`tw-w-full tw-h-[50px] tw-py-[5px] tw-px-[20px] tw-flex tw-items-center tw-border-b ${activeRoute === url ? 'active-router' : ''}`} onClick={(e) => this.routerToCategoryPage(item, e)}>{item.title?.toUpperCase()}</div>)

                                })}
                            </div>)}
                        </div>
                    </div>}
                </React.Fragment>}
            </React.Fragment>

        );
    }
}

function mapStateToProps(state) {
    return {
        loginStatus: UserSelectors.selectLoginStatus(state),
        shoppingCartArr: ShoppingCartSelectors.selectShoppingCartData(state),
        baseConfig: BaseConfigSelectors.selectBaseConfig(state),
    };
}


function mapDispatchToProps(dispatch) {
    return {
        categoryActions: bindActionCreators(categoryActionCreator, dispatch),
        authActions: bindActionCreators(authActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
