export default {
    GLOBAL_ALERT                               : 'globalAlert',
    ROUTER_LIST                                : 'router_list',
    CURRENT_PRODUCT                            : 'current_product',

    ROUTER_CONFIG                              : 'router_config',
    BASE_CONFIG                                : 'base_config',

    CURRENT_CATEGORY                           : 'current_category',
    
     // 用户
     CURRENT_USER                               : 'current_user',
     AUTHENTICATION                             : 'authentication',
     LOGIN_STATUS                               : 'login_status',

     // 购物车
     SHOPPING_CART                              : 'shopping_cart'
  };
  

export const LOGIN_CONSTANTS = {
  LOGGED_IN                         : 'LOGGED_IN',
  LOGGED_OUT                        : 'LOGGED_OUT',
}

export const PAYMENT_TYPE_CONSTANTS = {
  STRIPE                         : 'Stripe',
  IBAN                           : 'Iban',
}