import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import RDSButton from '../components/common/RDSButton';

import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import StarIcon from '../icons/StarIcon';
import PagePathConstants from '../constants/router/PagePathConstants';
import * as tagActionCreator from '../actions/tagActionCreator';
import * as productActionCreator from '../actions/productActionCreator';
import * as BaseConfigSelectors from '../selectors/BaseConfigSelectors';
import { BASE_URL } from '../constants/api/ApiEndpointConstants';
import Loading from '../components/common/LoadingIndicator';

class HomePage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            productTagList: [],
            isLoading: false
        }
        this.renderWhiteArrowBtn = this.renderWhiteArrowBtn.bind(this);
        this.routeToDetailPage = this.routeToDetailPage.bind(this);
        this.routeToSpecifiedPage = this.routeToSpecifiedPage.bind(this);
        this.fetchProductTagList = this.fetchProductTagList.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.routeToProductDetailPage = this.routeToProductDetailPage.bind(this);
    }

    componentDidMount() {
        this.renderWhiteArrowBtn();
        this.fetchProductTagList();
    }

    toggleLoading() {
        this.setState({isLoading: !this.state.isLoading});
    }

    fetchProductTagList() {
        // this.toggleLoading();
        const { tagActions, productActions } = this.props;
        tagActions && tagActions.fetchProductTagList(1)
            .then((tagList) => {
                this.setState({ productTagList: tagList }, () => {
                    tagList.map((tag, index) => {
                        productActions && productActions.fetchProductsByTagId(tag.id)
                            .then((productList) => {
                                // this.toggleLoading();
                                let { productTagList } = this.state;
                                productTagList[index]['productList'] = productList;
                                this.setState({ productTagList }, () => {
                                });
                            })
                            .catch();
                    })
                });
            })
            .catch();
    }

    routeToSpecifiedPage(url) {
        const { history } = this.props;
        history && history.push(url)
    }


    renderWhiteArrowBtn() {
        let nextBtns = document.querySelectorAll('.swiper-button-next')
        let prevBtns = document.querySelectorAll('.swiper-button-prev')
        for (let i = 0; i < nextBtns.length; i++) {
            nextBtns[i].classList.add('btn-white');
            prevBtns[i].classList.add('btn-white');
        }
    }

    routeToDetailPage(id) {
        const { history, location } = this.props;
        const path = PagePathConstants.PRODUCT_DETAIL.replace(':id', id);
        history && history.push({pathname: path, search: location.search});
    }

    routeToProductDetailPage(id) {
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.PRODUCT_DETAIL.replace(':id', id), search: location.search});
    }
    render() {
        const { productTagList, isLoading } = this.state;
        const { baseConfig } = this.props;
        const bannerArr = baseConfig && baseConfig.bannerArr && JSON.parse(baseConfig.bannerArr);

        const formatProductTagList = productTagList && productTagList.filter(tag => tag.productList && tag.productList.length > 0);
        return (
            <div className='tw-w-full tw-h-full'>
                {isLoading && <Loading />}
                <div className='tw-w-full tw-relative hs-homepage-banner'>
                    <Swiper modules={[Navigation]}
                        navigation>
                        {bannerArr && bannerArr.map(item => {
                            return (<SwiperSlide >
                                
                                <img src={BASE_URL + item.img} alt='swiper-img' className='tw-w-full tw-h-auto tw-relative' />
                                <div className='tw-absolute phone:tw-w-full tw-w-[600px] tw-left-[100px] phone:tw-left-0 tw-bottom-[55px] tw-flex tw-flex-col tw-justify-center tw-items-center'>
                                        {item.title && <div className='tw-text-[70px] greatVibes-font tw-text-[#93646A] tw-text-center'>{item.title}</div>}
                                        {item.description && <div className='tw-text-[18px] phone:tw-px-[20px] tw-text-center tw-text-body-text-1 lora-font'>{item.description}</div>}
                                        {item.url && <RDSButton className="tw-mt-[30px] tw-text-white playfair-font" onClick={() => this.routeToSpecifiedPage(item.url)}>TO SHOP</RDSButton>}
                                    </div>
                                {/* <div className='tw-w-full tw-h-[760px] tw-relative' style={{ backgroundImage: `url(${BASE_URL + item.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                
                                </div> */}
                                <br style={{clear: 'both'}} />
                            </SwiperSlide>)
                        })}
                    </Swiper>
                </div>
                {formatProductTagList && formatProductTagList.map((tag, index) => {
                    return (<React.Fragment>
                        <div className='tw-py-[20px] tw-text-body-text-1'>
                            <div className='tw-w-[390px] phone:tw-w-full tw-mx-auto tw-flex tw-flex-col tw-justify-center tw-items-center'>
                                <div className='tw-text-[32px] phone:tw-text-[28px] playfair-font tw-font-[300] tw-text-center'>{tag.title}</div>
                                <div className='tw-mt-[24px] phone:tw-px-[20px] sourceSansPro-font tw-text-[18px] tw-opacity-[0.7] tw-text-center'>{tag.content}</div>
                            </div>
                            <div className='tw-w-[1240px] phone:tw-w-full phone:tw-px-[15px] tw-px-[68px] tw-mx-auto tw-h-auto phone:tw-h-auto sourceSansPro-font tw-relative'>
                                <div className='tw-w-full tw-h-full tw-py-[30px]'>
                                    <div className='tw-w-full tw-h-full tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                                        {tag.productList && tag.productList.map(item => {
                                            const img = item.image && (BASE_URL + JSON.parse(item.image)[0]);
                                            const originalPrice = parseFloat(item.originalPrice);
                                            const currentPrice = parseFloat(item.currentPrice);
                                            const discount = Math.floor(((originalPrice - currentPrice) / originalPrice) * 100);
                                            return (<div className="tw-w-[24%] phone:tw-w-[47%] tw-overflow-hidden tw-mb-[30px]" key={item.id} onClick={() => this.routeToProductDetailPage(item.id)}>
                                                <div className='tw-overflow-hidden tw-w-full tw-rounded-[15px] '>
                                                    <div className='tw-w-full tw-pb-[100%] tw-relative product-img' style={{ backgroundImage: `url(${img})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} onClick={() => this.routeToDetailPage(item.id)}>
                                                        <div className='tw-absolute tw-top-[10px] tw-left-[10px] tw-font-bold tw-text-white tw-font-[20px] tw-px-[10px] tw-py-[3px] tw-rounded-[8px] tw-bg-brand-primary'>{discount}% OFF</div>
                                                    </div>
                                                </div>

                                                <div className='tw-mt-[10px] tw-h-[45px] tw-line-clamp-2 tw-opacity-[0.8] tw-text-[15px]'>{item.title}</div>
                                                <div className='tw-flex tw-items-center tw-my-[5px]'>
                                                    {new Array(item.star).fill(undefined).map(() => {
                                                        return <StarIcon className="tw-mr-[10px]" size={20} />
                                                    })}
                                                </div>
                                                <div className='tw-flex tw-justify-between tw-items-center'>
                                                    <div className='tw-line-through'>${originalPrice}</div>
                                                    <div className='tw-text-light-red tw-text-[20px]'>${currentPrice}</div>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {index === 1 && <div className='tw-w-[1240px] phone:tw-w-full phone:tw-mx-0 phone:tw-h-[50px] tw-mx-auto tw-h-[135px] tw-rounded-[20px]' style={{ backgroundImage: `url(${baseConfig && (BASE_URL + baseConfig.adPic)})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>}
                    </React.Fragment>)
                })}
                
                <div className='tw-py-[20px] tw-px-[20px] tw-text-body-text-1'>
                    <div className='tw-w-[1240px] phone:tw-w-full tw-mx-auto tw-h-[250px] phone:tw-h-auto tw-flex phone:tw-flex-wrap tw-justify-between tw-items-center'>
                        <div className='phone:tw-w-[45%] phone:tw-mb-[20px] tw-flex tw-flex-col tw-items-center'>
                            <div className='tw-px-[40px] tw-pt-[40px] tw-pb-[20px] phone:tw-px-[20px] phone:tw-py-[20px] tw-flex tw-flex-col tw-justify-between tw-items-center'>
                                <div className='tw-w-[80px] tw-h-[80px] phone:tw-w-[60px] phone:tw-h-[60px] tw-rounded-[10px] tw-bg-[#2222221A] tw-flex tw-justify-center tw-items-center tw-text-body-text-1'>
                                    <svg width="41" height="41" className="phone:tw-w-[31px] phone:tw-h-[30px]" viewBox='0 0 41 40'><path d="M34.8 28.565a1.25 1.25 0 1 1-2.144-1.286A14.093 14.093 0 0 0 34.666 20c0-7.824-6.342-14.167-14.166-14.167-7.824 0-14.167 6.343-14.167 14.167S12.676 34.167 20.5 34.167c2.564.004 5.08-.69 7.278-2.01a1.247 1.247 0 0 1 1.715.429 1.252 1.252 0 0 1-.429 1.715 16.593 16.593 0 0 1-8.564 2.366c-9.204 0-16.667-7.462-16.667-16.667 0-9.205 7.463-16.667 16.667-16.667 9.205 0 16.667 7.462 16.667 16.667 0 3.058-.827 5.999-2.367 8.565ZM15.132 19.12l3.295 3.281 7.439-7.45a1.25 1.25 0 1 1 1.769 1.767l-8.321 8.333a1.25 1.25 0 0 1-1.767.002l-4.179-4.162a1.25 1.25 0 1 1 1.764-1.77Z" fill="#222222"></path></svg>
                                </div>
                                <div className='tw-mt-[20px] tw-text-[20px] phone:tw-text-[14px] tw-font-normal phone:tw-text-center'>FREE SHIPPING</div>
                            </div>
                            <div className='tw-text-[14px] tw-text-lgith-grey phone:tw-text-center'>Free shipping from ${baseConfig.freeShippingAmount}</div>
                        </div>
                        {/* <div className='phone:tw-w-[45%] phone:tw-mb-[20px] tw-flex tw-flex-col tw-items-center'>
                            <div className='tw-px-[40px] tw-pt-[40px] tw-pb-[20px] phone:tw-px-[20px] phone:tw-py-[20px] tw-flex tw-flex-col tw-justify-between tw-items-center'>
                                <div className='tw-w-[80px] tw-h-[80px] phone:tw-w-[60px] phone:tw-h-[60px] tw-rounded-[10px] tw-bg-[#2222221A] tw-flex tw-justify-center tw-items-center tw-text-body-text-1'>
                                    <svg width="41" height="40" className="phone:tw-w-[31px] phone:tw-h-[30px]" viewBox='0 0 41 40' fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.833 15.333c-.222-.666-.888-.889-1.333-.666-.667 0-.889.666-.889 1.333l1.334 4.222c.222.445.666.89 1.11.89h.223c.667-.223.889-.89.667-1.334l-1.112-4.445Zm-3.11 11.778c-2.223 0-3.778 1.778-3.778 3.778 0 2.222 1.777 4 3.777 4S20.5 33.11 20.5 31.11c0-2.222-1.778-4-3.778-4Zm0 5.556c-.89 0-1.556-.667-1.556-1.778 0-.889.666-1.556 1.555-1.556.89 0 1.556.89 1.556 1.778 0 .889-.667 1.556-1.556 1.556Zm13.333-5.556c-2.223 0-3.778 1.778-3.778 3.778 0 2.222 1.778 4 3.778 4s3.777-1.778 3.777-3.778c0-2.222-1.777-4-3.777-4Zm0 5.556c-.89 0-1.556-.667-1.556-1.778 0-.889.667-1.556 1.556-1.556s1.777.667 1.777 1.556c-.222 1.111-.888 1.778-1.777 1.778Z" fill="currentColor"></path><path d="M37.167 11.111v-.444s0-.223-.223-.223c0 0 0-.222-.222-.222H36.5s-.222 0-.222-.222H11.389L10.5 6.444c-.222-.444-.667-.888-1.111-.888H4.944c-.666 0-1.11.444-1.11 1.11 0 .667.444 1.112 1.11 1.112H8.5l4.222 16.889c.223.444.667.889 1.111.889h17.778c.445 0 .889-.223 1.111-.667l4.445-13.333v-.445c0 .222 0 0 0 0Zm-6.445 12.222h-16l-2.889-11.11H34.5l-3.778 11.11Z" fill="currentColor"></path><path d="M29.167 14.667c-.667-.223-1.111.222-1.334.666L26.5 19.556c-.222.666.222 1.11.667 1.333h.222c.444 0 .889-.222 1.111-.889l1.333-4.222c.223-.445 0-1.111-.666-1.111Z" fill="currentColor"></path></svg>
                                </div>
                                <div className='tw-mt-[20px] tw-text-[20px] phone:tw-text-[14px] tw-font-normal phone:tw-text-center'>RETURNS</div>
                            </div>
                            <div className='tw-text-[14px] tw-text-lgith-grey phone:tw-text-center'>Within 30 days for an exchange.</div>
                        </div> */}
                        <div className='phone:tw-w-[45%] phone:tw-mb-[20px] tw-flex tw-flex-col tw-items-center'>
                            <div className='tw-px-[40px] tw-pt-[40px] tw-pb-[20px] phone:tw-px-[20px] phone:tw-py-[20px] tw-flex tw-flex-col tw-justify-between tw-items-center'>
                                <div className='tw-w-[80px] tw-h-[80px] phone:tw-w-[60px] phone:tw-h-[60px] tw-rounded-[10px] tw-bg-[#2222221A] tw-flex tw-justify-center tw-items-center tw-text-body-text-1'>
                                    <svg width="41" height="40" className="phone:tw-w-[31px] phone:tw-h-[30px]" viewBox='0 0 41 40' fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M38.62 5.54c-.64-.66-1.52-1.02-2.38-1l-10.36.12c-.08 0-.18 0-.28.02l-5.9.08c-1.28.02-2.46.52-3.32 1.4l-13.7 13.7c-.82.82-1.28 1.94-1.28 3.12 0 1.18.46 2.3 1.28 3.12l8.8 8.8c.82.82 1.94 1.28 3.12 1.28 1.18 0 2.3-.46 3.12-1.28l.02-.02c.82.8 1.9 1.22 3.06 1.22 1.18 0 2.3-.46 3.12-1.28l13.76-13.76a4.79 4.79 0 0 0 1.4-3.14l.48-9.96c.02-.92-.32-1.8-.94-2.42ZM8.86 19.76c-.82.82-1.28 1.94-1.28 3.12 0 1.18.46 2.3 1.28 3.12l7.18 7.18-.02.02c-.74.74-2.1.74-2.84 0l-8.8-8.8c-.78-.78-.78-2.06 0-2.84l13.7-13.7c.44-.44 1-.68 1.64-.7l1.74-.02-12.6 12.62ZM37.1 7.8l-.46 9.98c-.04.6-.28 1.14-.7 1.56L22.18 33.1c-.74.74-2.1.74-2.84 0l-8.8-8.8c-.38-.38-.6-.9-.6-1.42 0-.54.22-1.04.6-1.42l13.7-13.7c.38-.38.88-.62 1.44-.68.04 0 .1 0 .16-.02l10.44-.12c.24 0 .46.1.62.26l.02.02c.12.14.2.36.18.58Z" fill="currentColor"></path><path d="M31.42 7.94c-2.02 0-3.66 1.64-3.66 3.66 0 2.02 1.64 3.66 3.66 3.66 2.02 0 3.66-1.64 3.66-3.66 0-2.02-1.64-3.66-3.66-3.66Zm1.38 3.68c0 .76-.62 1.38-1.38 1.38-.76 0-1.38-.62-1.38-1.38 0-.76.62-1.38 1.38-1.38.76 0 1.38.62 1.38 1.38Z" fill="currentColor"></path></svg>
                                </div>
                                <div className='tw-mt-[20px] tw-text-[20px] phone:tw-text-[14px] tw-font-normal phone:tw-text-center'>ONLINE SUPPORT</div>
                            </div>
                            <div className='tw-text-[14px] tw-text-lgith-grey phone:tw-text-center'>24 hours a day, 7 days a week</div>
                        </div>
                        <div className='phone:tw-w-[45%] phone:tw-mb-[20px] tw-flex tw-flex-col tw-items-center'>
                            <div className='tw-px-[40px] tw-pt-[40px] tw-pb-[20px] phone:tw-px-[20px] phone:tw-py-[20px] tw-flex tw-flex-col tw-justify-between tw-items-center'>
                                <div className='tw-w-[80px] tw-h-[80px] phone:tw-w-[60px] phone:tw-h-[60px] tw-rounded-[10px] tw-bg-[#2222221A] tw-flex tw-justify-center tw-items-center tw-text-body-text-1'>
                                    <svg width="41" height="40" className="phone:tw-w-[31px] phone:tw-h-[30px]" viewBox='0 0 41 40' fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.57 23.554a4.368 4.368 0 0 1-2.912-1.048l-2.576-2.212a1.456 1.456 0 1 1 1.892-2.213l2.576 2.213c.535.433 1.3.433 1.834 0L34.922 9.886 7.265 9.784a1.456 1.456 0 0 1 0-2.911h27.657a2.911 2.911 0 0 1 1.805 5.313L23.19 22.593a4.369 4.369 0 0 1-2.62.961Zm17.365 5.211V17.12a1.455 1.455 0 1 0-2.91 0v11.646c0 .804-.652 1.455-1.456 1.455H13.19a1.456 1.456 0 0 0 0 2.911h20.38a4.367 4.367 0 0 0 4.365-4.367ZM14.645 14.21c0-.804-.651-1.455-1.455-1.455H4.455a1.456 1.456 0 0 0 0 2.91h8.735c.803 0 1.455-.651 1.455-1.455Zm0 11.645c0-.804-.651-1.455-1.455-1.455H8.823a1.456 1.456 0 0 0 0 2.91h4.367c.803 0 1.455-.651 1.455-1.455Z" fill="currentColor"></path></svg>
                                </div>
                                <div className='tw-mt-[20px] tw-text-[20px] phone:tw-text-[14px] tw-font-normal phone:tw-text-center'>FLEXIBLE PAYMENT</div>
                            </div>
                            <div className='tw-text-[14px] tw-text-lgith-grey phone:tw-text-center'>Pay with Multiple Credit Cards</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
        baseConfig: BaseConfigSelectors.selectBaseConfig(state),
    };
}


function mapDispatchToProps(dispatch) {
    return {
        tagActions: bindActionCreators(tagActionCreator, dispatch),
        productActions: bindActionCreators(productActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
