import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaymentsImg from '../../../images/payments.png';
import * as BaseConfigSelectors from '../../../selectors/BaseConfigSelectors';
import PagePathConstants from '../../../constants/router/PagePathConstants';

class Footer extends Component {
    routeToPage(url) {
        const { history, location } = this.props;
        history && history.push({pathname: url, search: location.search});
    }
    render() {
        const { baseConfig } = this.props;
        return (
            <div className='tw-bg-[#00142D] tw-pt-[30px] lora-font phone:tw-px-[20px] phone:tw-pb-[30px]'>
                <div className='tw-w-full tw-mx-auto tw-h-[220px] phone:tw-h-auto tw-text-white tw-select-none tw-flex tw-justify-center tw-items-start phone:tw-flex-wrap'>
                    <div className='phone:tw-text-center'>
                        <div className='tw-text-[22px] phone:tw-text-[18px]'>CONTACT</div>
                        <div className='tw-mt-[20px] tw-flex phone:tw-justify-center tw-items-center phone:tw-text-[14px]'>
                            <div>Email:</div>
                            <div className='tw-ml-[20px] phone:tw-ml-0 tw-text-light-blue'>{`${baseConfig.partnerEmail}`}</div>
                        </div>
                        <div className='tw-mt-[10px] tw-flex  phone:tw-justify-center tw-items-center phone:tw-text-[14px]'>
                            <div>Company address:</div>
                            <div className='tw-ml-[20px] phone:tw-ml-0 '>{baseConfig.companyAddress}</div>
                        </div>

                        <div className='tw-mt-[10px] tw-flex  phone:tw-justify-center tw-items-center phone:tw-text-[14px]'>
                            <div>Phone:</div>
                            <div className='tw-ml-[20px] phone:tw-ml-0 '>{baseConfig.phone}</div>
                        </div>
                        <div className='tw-mt-[10px] tw-flex-col tw-items-center tw-justify-between  phone:tw-items-start'>
                            <div className='tw-w-[255px] tw-h-[22px] phone:tw-mt-[20px] tw-mt-[20px]' style={{ backgroundImage: `url(${PaymentsImg})`, backgroundSize: 'cover' }}></div>
                        </div>
                    </div>

                    <div className='tw-ml-[100px] phone:tw-ml-0 phone:tw-mt-[50px] phone:tw-text-center'>
                        <div className='tw-text-[22px] phone:tw-text-[18px]'>POLICIES AND TERMS</div>
                        <div className='tw-mt-[20px] tw-text-[16px] phone:tw-text-[14px]' onClick={() => this.routeToPage(PagePathConstants.ABOUT_US)}>About us</div>
                        <div className='tw-mt-[10px] tw-text-[16px] phone:tw-text-[14px]' onClick={() => this.routeToPage(PagePathConstants.SHIPPING_POLICY)}>Shipping Policy</div>
                        <div className='tw-mt-[10px] tw-text-[16px] phone:tw-text-[14px]' onClick={() => this.routeToPage(PagePathConstants.PROVACY_POLICY)}>Privacy Policy</div>
                    </div>
                </div>


            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        baseConfig: BaseConfigSelectors.selectBaseConfig(state),
    };
}


// function mapDispatchToProps(dispatch) {
//     return {
//         tagActions: bindActionCreators(tagActionCreator, dispatch),
//         productActions: bindActionCreators(productActionCreator, dispatch),
//     };
// }

export default connect(mapStateToProps, null)(Footer);