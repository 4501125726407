export default {
  SHOW_GLOBAL_ALERT : 'SHOW_GLOBAL_ALERT',
  UPDATE_SINGLE_PRODUCT : 'UPDATE_SINGLE_PRODUCT',
  UPDATE_PRODUCT_SKUDATA : 'UPDATE_PRODUCT_SKUDATA',


  //Router
  UPDATE_ROUTER_CONFIG : 'UPDATE_ROUTER_CONFIG',

  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',
  LOGOUT: 'LOGOUT',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',

  UPDATE_CURRENT_CATEGORY: 'UPDATE_CURRENT_CATEGORY',

  UPDATE_BASE_CONFIG: 'UPDATE_BASE_CONFIG',


  ADD_PRODUCT_TO_CART: 'ADD_PRODUCT_TO_CART',
  REMOVE_PRODUCT_FROM_CART: 'REMOVE_PRODUCT_FROM_CART',
  UPDATE_PRODUCT_QUANTITY: 'UPDATE_PRODUCT_QUANTITY',
}
