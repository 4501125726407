import { combineReducers } from 'redux-immutable';
import { routerReducer } from 'react-router-redux';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import { GlobalAlertReducer } from './GlobalAlertReducer';
import { ProductDetailReducer } from './ProductDetailReducer';
import { UserReducer } from './UserReducer';
import { BaseConfigReducer } from './BaseConfigReducer';
import { CategoryReducer } from './CategoryReducer';
import { ShoppingCartReducer } from './ShoppingCartReducer';

const appReducer = combineReducers({
  [ReducerNameConstants.GLOBAL_ALERT]: GlobalAlertReducer,
  [ReducerNameConstants.PRODUCT_DETAIL]: ProductDetailReducer,
  [ReducerNameConstants.USER]: UserReducer,
  [ReducerNameConstants.BASE_CONFIG]: BaseConfigReducer,
  [ReducerNameConstants.CATEGORY]: CategoryReducer,
  [ReducerNameConstants.SHOPPING_CART]: ShoppingCartReducer,
  router: routerReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  return appReducer(newState, action);
};

export default rootReducer;
