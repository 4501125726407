import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode, Thumbs } from 'swiper';
import QuantityController from '../components/common/quantity/index';
import CopyIcon from '../icons/CopyIcon';
import CustomButton from '../components/common/button/index';
import ShoppingCartModal from '../components/common/shoppingCartModal/index';

import { EDSRichTextRenderer } from '../components/common/rich_text/index';
import * as productActionCreator from '../actions/productActionCreator';
import * as tagActionCreator from '../actions/tagActionCreator';
import * as shoppingCartActionCreator from '../actions/shoppingCartActionCreator';
import Loading from '../components/common/LoadingIndicator';
import * as ProductDetailSelectors from '../selectors/ProductDetailSelectors'
import * as BaseConfigSelectors from '../selectors/BaseConfigSelectors'
import * as ShoppingCartSelectors from '../selectors/ShoppingCartSelectors'
import MobileDetectUtil from '../utils/MobileDetectUtil';
import StarIcon from '../icons/StarIcon';
import CouponImg from '../images/coupon/coupon.webp';


import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/scss/pagination';

import Footer from '../components/common/footer';
import PagePathConstants from '../constants/router/PagePathConstants';
import { BASE_URL } from '../constants/api/ApiEndpointConstants';
import ProductHelper from '../helpers/ProductHelper';
import classNames from 'classnames';
import XIcon from '../icons/XIcon';

class ProductDetailPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showLoading: false,
            showCouponModal: false,
            thumbsSwiper: null,
            marginWidth: 0,
            showShoppingCartModal: false,
            productTagList: [],
            activeCommentTab: 'all',
            showCommentImage: false,
            commentImg: '',
        }
        this.toggleLoading = this.toggleLoading.bind(this);
        this.setThumbsSwiper = this.setThumbsSwiper.bind(this);
        this.handleSelectSku = this.handleSelectSku.bind(this);
        this.renderWhiteArrowBtn = this.renderWhiteArrowBtn.bind(this);
        this.toggleShoppingCartModal = this.toggleShoppingCartModal.bind(this);
        this.addProductToCart = this.addProductToCart.bind(this);
        this.fetchProductTagList = this.fetchProductTagList.bind(this);
        this.handleReceiveCoupon = this.handleReceiveCoupon.bind(this);
        this.handleCheckoutCurrentProduct = this.handleCheckoutCurrentProduct.bind(this);
        this.routeToProductDetailPage = this.routeToProductDetailPage.bind(this);
        this.fetchProductAndCategory = this.fetchProductAndCategory.bind(this);
        this.handleChangeCommentTab = this.handleChangeCommentTab.bind(this);
        this.closeCommentImg = this.closeCommentImg.bind(this);

        this.descContainerRef = React.createRef();
        this.quantityRef = React.createRef();
    }

    componentDidMount() {
        const { location, productActions } = this.props;
        const productId = location.pathname.split(PagePathConstants.PRODUCT_DETAIL.replace(':id', ''))[1];

        this.fetchProductAndCategory(productId);
    }

    handleChangeCommentTab(activeCommentTab) {
        this.setState({ activeCommentTab });
    }

    closeCommentImg() {
        this.setState({ showCommentImage: false });
    }

    fetchProductAndCategory(id) {
        const { productActions } = this.props;
        this.toggleLoading();
        productActions && productActions.fetchSingleProductById(id)
            .then(this.toggleLoading)
            .catch(this.toggleLoading)

        if (this.descContainerRef) {
            let offsetWidth = this.descContainerRef.current.offsetWidth;
            let marginWidth = (offsetWidth - 850) / 2;
            this.setState({ marginWidth });
        }

        this.renderWhiteArrowBtn();
        this.fetchProductTagList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            const { location, productActions } = nextProps;
            const productId = location.pathname.split(PagePathConstants.PRODUCT_DETAIL.replace(':id', ''))[1];
            this.fetchProductAndCategory(productId);
        }
    }


    handlePreviewImg(imgUrl) {
        this.setState({ showCommentImage: true, commentImg: imgUrl });
    }

    fetchProductTagList() {
        const { tagActions, productActions } = this.props;
        tagActions && tagActions.fetchProductTagList(0)
            .then((tagList) => {
                this.setState({ productTagList: tagList });
                tagList.map((tag, index) => {
                    productActions && productActions.fetchProductsByTagId(tag.id)
                        .then((productList) => {
                            let { productTagList } = this.state;
                            if (productList.length > 0 && productTagList[index]) {
                                productTagList[index]['productList'] = productList;
                                this.setState({ productTagList });
                            }
                        })
                })
            })
    }

    handleReceiveCoupon() {
        const { baseConfig } = this.props;
        if (navigator.clipboard) {
            this.setState({ showCouponModal: true });
            navigator.clipboard.writeText(baseConfig.couponCode);
            setTimeout(() => {
                this.setState({ showCouponModal: false })
            }, 3000);
        }

    }

    renderWhiteArrowBtn() {
        let nextBtns = document.querySelectorAll('.swiper-button-next')
        let prevBtns = document.querySelectorAll('.swiper-button-prev')
        for (let i = 0; i < nextBtns.length; i++) {
            nextBtns[i].classList.add('btn-white');
            prevBtns[i].classList.add('btn-white');
        }
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    setThumbsSwiper(swiper) {
        this.setState({ thumbsSwiper: swiper });
    }

    handleSelectSku(index, incomingItem) {
        const { productActions, currentProduct } = this.props;
        let newProductInfo = Object.assign({}, currentProduct);
        if (newProductInfo.skulist) {
            newProductInfo.skulist[index].selectedValue = incomingItem;
        }
        productActions && productActions.updateCurrentProductSkuData(newProductInfo);
    }

    toggleShoppingCartModal() {
        this.setState({ showShoppingCartModal: !this.state.showShoppingCartModal }, () => {
            if (this.state.showShoppingCartModal) {
                document.getElementById('root').classList.add('overflow-y-hidden')
            } else {
                document.getElementById('root').classList.remove('overflow-y-hidden')
            }
        })
    }

    componentWillUnmount() {
        document.getElementById('root').classList.remove('overflow-y-hidden');
    }

    addProductToCart() {
        const { currentProduct, shoppingCartActions } = this.props;
        currentProduct['quantity'] = 0;
        if (this.quantityRef && this.quantityRef.current) {
            currentProduct['quantity'] = this.quantityRef.current.state.quantity
        }
        shoppingCartActions && shoppingCartActions.addProductToShoppingCart(currentProduct);
        this.toggleShoppingCartModal();
    }

    handleCheckoutCurrentProduct() {
        const { currentProduct, history, location } = this.props;
        currentProduct['quantity'] = this.quantityRef.current.state.quantity;
        ProductHelper.saveCheckoutProductList(false, currentProduct);
        history && history.push({pathname: PagePathConstants.CHECKOUT_PAGE, search: location.search});
    }

    routeToProductDetailPage(id) {
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.PRODUCT_DETAIL.replace(':id', id), search: location.search});
    }

    render() {
        const { showLoading, thumbsSwiper, marginWidth, productTagList, showShoppingCartModal, showCouponModal, activeCommentTab, showCommentImage, commentImg } = this.state;
        const { currentProduct, baseConfig } = this.props;
        const formatProductMap = currentProduct && currentProduct.image && JSON.parse(currentProduct.image);
        const productMap = formatProductMap && formatProductMap.map(item => BASE_URL + item);
        const isMobile = MobileDetectUtil.isMobile();
        let save;
        if (currentProduct) {
            save = (1 - ((parseFloat(currentProduct.currentPrice) / parseFloat(currentProduct.originalPrice)).toFixed(2))) * 100
            save = Math.round(save * 100) / 100;
        }
        const formatProductTagList = productTagList && productTagList.filter(tag => tag.productList && tag.productList.length > 0);
        return (
            <div className='tw-mt-[20px] phone:tw-mt-0'>
                {showLoading && <Loading />}
                {showCommentImage && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-flex tw-items-center tw-justify-center tw-bg-[rgba(0,0,0,0.7)] tw-z-[1000000]'>
                    <div className='tw-relative'>
                        <img src={commentImg} className='tw-h-[500px] phone:tw-hidden' alt='comment_img' />
                        <div className='tw-h-[500px] tw-w-[350px] tw-hidden phone:tw-block' style={{backgroundImage: `url(${commentImg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} />
                        <div onClick={this.closeCommentImg} className='tw-absolute tw-right-[-18px] tw-top-[-18px] tw-bg-[rgba(0,0,0,0.2)] tw-rounded-full tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-w-[36px] tw-h-[36px]'>
                            <XIcon size={24} color='white' />
                        </div>
                    </div>
                </div>}
                {showCouponModal && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-bg-[rgba(0,0,0,0.5)] tw-flex tw-justify-center tw-items-center tw-z-[1000]'>
                    <div className='tw-w-[428px] tw-h-[260px] phone:tw-w-[300px] phone:tw-h-[182px] tw-relative tw-flex' style={{ backgroundImage: `url(${CouponImg})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                        <div className='tw-w-[332px] tw-h-[40px] phone:tw-w-[232px] tw-mx-[50px] tw-absolute tw-bottom-[30px] phone:tw-bottom-[10px] phone:tw-mx-[30px]  tw-text-[18px] phone:tw-text-[14px] tw-text-[#f86d45] tw-text-center'>Copy successfully, use at checkout</div>
                    </div>
                </div>}
                <ShoppingCartModal fromPage='productDetail' showModal={showShoppingCartModal} toggleShoppingCartModal={this.toggleShoppingCartModal} {...this.props} />
                <div className='tw-w-full tw-h-[650px] phone:tw-h-auto tw-mx-auto tw-flex tw-justify-center phone:tw-flex-wrap'>
                    <div className='tw-w-[600px] phone:tw-w-full tw-h-[650px] phone:tw-h-auto tw-flex tw-items-start phone:tw-flex-col-reverse phone:tw-px-[20px]' style={{ marginLeft: (isMobile ? '0px' : marginWidth + 'px') }}>
                        <Swiper
                            direction={isMobile ? 'horizontal' : 'vertical'}
                            onSwiper={this.setThumbsSwiper}
                            slidesPerView={6}
                            className="tw-w-[100px] tw-h-[500px] phone:tw-w-full phone:tw-h-[50px] phone:tw-mt-[20px]">
                            {productMap && productMap.map((item, i) => {
                                return (<SwiperSlide key={i}>
                                    <div key={item} className='tw-w-[72px] tw-h-[72px] phone:tw-w-[50px] phone:tw-h-[50px] tw-rounded-[10px] tw-relative' style={{ backgroundImage: `url(${item})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                </SwiperSlide>)
                            })}
                        </Swiper>

                        <Swiper
                            className='tw-w-[485px] phone:tw-w-full '
                            navigation
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}>
                            {productMap && productMap.map((item, i) => {
                                return (<SwiperSlide key={i}>
                                    <div className='tw-w-[485px] phone:tw-w-full  tw-h-[650px] phone:tw-h-0 phone:tw-pb-[100%] tw-relative' style={{ backgroundImage: `url(${item})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                </SwiperSlide>)
                            })}
                        </Swiper>
                    </div>
                    <div className='tw-w-[calc(100%-600px)] phone:tw-w-full tw-h-full phone:tw-h-auto tw-overflow-scroll scrollbar-hidden phone:tw-mt-[20px] phone:tw-mx-[20px]' ref={this.descContainerRef}>
                        <div className='phone:tw-pl-0 tw-pl-[80px] tw-w-[calc(1440px-670px)] phone:tw-w-full tw-min-w-[450px] phone:tw-min-w-full playfair-font tw-text-body-text-1'>
                            <div className='tw-text-[26px]'>{currentProduct && currentProduct.title}</div>
                            <div className='tw-text-[32px] tw-mt-[5px] tw-flex tw-items-center'>
                                <div className=''>${currentProduct && currentProduct.currentPrice}</div>
                                <div className='tw-ml-[15px] tw-text-body-text-4 tw-text-[18px] tw-line-through'>${currentProduct && currentProduct.originalPrice}</div>
                                <div className='tw-ml-[10px] tw-text-light-red sourceSansPro-font tw-text-[18px]'>{`Save ${save}%`}</div>
                            </div>
                            {baseConfig && baseConfig.coupon && <div className='tw-mt-[20px] tw-rounded-[10px] tw-w-[404px] phone:tw-w-[100%] tw-h-[90px] tw-bg-[#ffefde] tw-flex tw-justify-center tw-items-center tw-relative sourceSansPro-font'>
                                <div className='tw-rounded-[10px] tw-w-[396px] phone:tw-w-full tw-h-[80px] tw-text-white product-coupon-container tw-flex tw-relative'>
                                    <div className='tw-w-[60%] tw-border-r-[2px] tw-border-dashed tw-border-[#ffefde] tw-px-[24px] tw-flex tw-flex-col tw-justify-center'>
                                        <div className='tw-text-[22px] tw-font-[300]'>{baseConfig.coupon}% OFF</div>
                                        <div className='tw-text-[14px] tw-font-[300'>Extra</div>

                                    </div>
                                    <div className='tw-absolute tw-top-[-3px] tw-left-[calc(60%-4px)] tw-w-[6px] tw-h-[6px] tw-bg-[#ffefde] tw-rotate-45'></div>
                                    <div className='tw-absolute tw-bottom-[-3px] tw-left-[calc(60%-4px)] tw-w-[6px] tw-h-[6px] tw-bg-[#ffefde] tw-rotate-45'></div>
                                    <div className='tw-w-[40%] tw-flex tw-flex-col tw-justify-center tw-items-center'>
                                        <div className='tw-text-[12px]'>Copy code</div>
                                        <div className='tw-mt-[5px] tw-px-[10px] tw-py-[5px] tw-bg-[#FEE7CD] tw-rounded-full tw-flex tw-items-center tw-text-light-red'>{baseConfig.couponCode} <CopyIcon onClick={this.handleReceiveCoupon} size={18} className="tw-ml-[10px]" /></div>
                                    </div>
                                </div>
                                <svg className="app-coupon-triangle app-coupon-triangle-0" xmlns="http://www.w3.org/2000/svg" width="106" height="96" viewBox="0 0 106 96" fill="none"><path d="M102.929 68.555c6.833 12.054-1.946 26.985-15.802 26.875l-68.312-.542C4.959 94.778-3.582 79.71 3.441 67.766l34.625-58.89C45.09-3.067 62.41-2.93 69.242 9.125l33.687 59.43z" fill="#fff" fillOpacity=".11"></path></svg>
                                <svg className="app-coupon-triangle app-coupon-triangle-1" xmlns="http://www.w3.org/2000/svg" width="106" height="96" viewBox="0 0 106 96" fill="none"><path d="M102.929 68.555c6.833 12.054-1.946 26.985-15.802 26.875l-68.312-.542C4.959 94.778-3.582 79.71 3.441 67.766l34.625-58.89C45.09-3.067 62.41-2.93 69.242 9.125l33.687 59.43z" fill="#fff" fillOpacity=".11"></path></svg>
                                <svg className="app-coupon-triangle app-coupon-triangle-2" xmlns="http://www.w3.org/2000/svg" width="106" height="96" viewBox="0 0 106 96" fill="none"><path d="M102.929 68.555c6.833 12.054-1.946 26.985-15.802 26.875l-68.312-.542C4.959 94.778-3.582 79.71 3.441 67.766l34.625-58.89C45.09-3.067 62.41-2.93 69.242 9.125l33.687 59.43z" fill="#fff" fillOpacity=".11"></path></svg>
                            </div>}

                            <div className='tw-mt-[20px] sourceSansPro-font tw-text-body-text-5'>
                                {currentProduct && currentProduct.skulist && currentProduct.skulist.map((sku, index) => {
                                    return <div className='tw-mb-[20px]' key={sku.title}>
                                        <div className='tw-text-[16px] tw-flex tw-items-center'>{sku.title}: <div className='tw-font-[500] tw-ml-[10px]'>{sku.selectedValue}</div></div>
                                        <div className='tw-flex tw-flex-wrap tw-mt-[15px]'>{sku.data.map(item => {
                                            return <div key={item} className={`tw-min-w-[118px] tw-min-h-[50px] tw-border tw-rounded-[10px] tw-px-[5px] tw-py-[5px] tw-flex tw-justify-center tw-items-center tw-mr-[20px] tw-mb-[10px] ${sku.selectedValue === item ? 'tw-border-[2px] tw-border-[#121B17]' : ''}`} onClick={() => this.handleSelectSku(index, item)}>
                                                {/* {item.img && <div className='tw-w-[42px] tw-h-[42px] tw-rounded-[10px] tw-mr-[15px]' style={{ backgroundImage: `url(${item.img})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>} */}
                                                <div className='tw-text-[14px] tw-max-w-[300px] overflow-ellipsis '>{item}</div>
                                            </div>
                                        })}</div>

                                    </div>
                                })}
                            </div>
                            <div className='tw-mt-[20px] sourceSansPro-font tw-text-body-text-5'>
                                <div className='tw-text-[16px]'>Quantity:</div>
                                <div className='tw-mt-[15px]'>
                                    <QuantityController ref={this.quantityRef} />
                                </div>
                            </div>
                            <div className='tw-mt-[24px]'>
                                <CustomButton color="primary" onClick={this.addProductToCart}>Add to cart</CustomButton>
                                <CustomButton className="tw-mt-[24px]" color='white' onClick={this.handleCheckoutCurrentProduct}>Buy it now</CustomButton>
                            </div>
                            <div className='tw-w-full tw-text-body-text-light-grey tw-text-[14px] tw-mt-[20px]'>
                                <div className='tw-flex tw-items-center tw-mt-[10px]'>
                                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M17.14 13.65h-3.457c.327-1.022.517-2.081.568-3.164h3.751a7.954 7.954 0 0 1-.862 3.165zm-5.784 4.259a13.405 13.405 0 0 0 1.968-3.285h3.226a8.038 8.038 0 0 1-5.194 3.285zM3.45 14.624h3.227a13.407 13.407 0 0 0 1.968 3.285 8.039 8.039 0 0 1-5.195-3.285zm-1.451-4.138h3.751c.051 1.083.24 2.142.568 3.165H2.86a7.97 7.97 0 0 1-.862-3.165zM2.86 6.35h3.457a12.244 12.244 0 0 0-.568 3.164H1.998A7.97 7.97 0 0 1 2.86 6.35zm5.784-4.258a13.416 13.416 0 0 0-1.968 3.285H3.449a8.04 8.04 0 0 1 5.195-3.285zm7.906 3.285h-3.226a13.414 13.414 0 0 0-1.968-3.285 8.04 8.04 0 0 1 5.194 3.285zm-4.3 0h-4.5A12.805 12.805 0 0 1 10 2.007a12.807 12.807 0 0 1 2.25 3.37zM6.734 9.513C6.79 8.43 7 7.37 7.358 6.35h5.284c.359 1.02.568 2.08.624 3.164H6.734zm.624 4.138a11.26 11.26 0 0 1-.624-3.165h6.532a11.26 11.26 0 0 1-.624 3.165H7.358zm.392.973h4.5A12.799 12.799 0 0 1 10 17.992a12.797 12.797 0 0 1-2.25-3.368zm10.252-5.11h-3.75a12.265 12.265 0 0 0-.569-3.165h3.457c.493.96.794 2.03.862 3.164zM1 10c0 4.963 4.037 9 9 9s9-4.037 9-9-4.037-9-9-9-9 4.037-9 9z" fill="currentColor"></path></svg>
                                    <div className='tw-ml-[20px]'>Free shipping from ${baseConfig.freeShippingAmount}</div>
                                </div>
                                {/* <div className='tw-flex tw-items-center tw-mt-[10px]'>
                                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M18.41 14.672a1.811 1.811 0 0 0-1.743-1.359c-.834 0-1.538.578-1.743 1.359H13.81v-4.366h5.238v4.366h-.638zm-1.743 1.552a1.04 1.04 0 0 1-.929-.582 1.042 1.042 0 0 1 0-.97 1.04 1.04 0 0 1 .929-.582c.404 0 .757.237.928.582a1.042 1.042 0 0 1 0 .97 1.04 1.04 0 0 1-.928.582zm-3.81-1.552h-5.4a1.811 1.811 0 0 0-1.743-1.359c-.833 0-1.538.578-1.743 1.359H.952V4.97h11.905v9.702zm-7.143 1.552a1.04 1.04 0 0 1-.928-.582 1.042 1.042 0 0 1 0-.97 1.04 1.04 0 0 1 .928-.582c.405 0 .757.237.929.582a1.042 1.042 0 0 1 0 .97 1.04 1.04 0 0 1-.929.582zM16.9 6.91l1.699 2.426h-4.79V6.91H16.9zm.486-.97H13.81V4H0v11.642h3.971c.205.78.91 1.358 1.743 1.358.834 0 1.538-.577 1.743-1.358H14.924c.205.78.91 1.358 1.743 1.358.833 0 1.538-.577 1.743-1.358H20V9.666L17.386 5.94z" fill="currentColor"></path></svg>
                                    <div className='tw-ml-[20px]'>Free returns</div>
                                </div> */}
                                <div className='tw-flex tw-items-center tw-mt-[10px]'>
                                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="m16.185 9.248-4.237-.92 4.46-4.46c.345 1.804.26 3.646-.223 5.38m-3.019 5.18-.092.091c-2.54 2.484-5.592 1.881-7.533.216l5.592-5.591 4.749 1.03a11.784 11.784 0 0 1-2.716 4.253m-8.094-7.91.092-.093a11.775 11.775 0 0 1 4.252-2.717l1.031 4.751-5.591 5.591c-1.666-1.94-2.268-4.993.216-7.532m8.45-3.548a11.7 11.7 0 0 1 2.202.212l-4.462 4.462-.919-4.235a11.829 11.829 0 0 1 3.18-.439m3.634-.193-.072-.27-.27-.073A12.802 12.802 0 0 0 4.477 5.74l-.099.099c-2.094 2.14-2.985 5.782-.205 8.894L2 16.905l.686.686 2.17-2.171c1.383 1.235 2.869 1.752 4.283 1.752 1.769 0 3.424-.796 4.614-1.96l.1-.1a12.802 12.802 0 0 0 3.304-12.336" fill="currentColor"></path></svg>
                                    <div className='tw-ml-[20px]'>Sustainably made</div>
                                </div>
                                <div className='tw-flex tw-items-center tw-mt-[10px]'>
                                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 1c2.757 0 5 2.243 5 5v.813H18v12.34H1V6.814h3.5V6c0-2.757 2.243-5 5-5zM17 7.813H2v10.34h15V7.814zM9.5 10.5a1.25 1.25 0 0 1 .5 2.394v2.318H9v-2.318a1.25 1.25 0 0 1 .5-2.394zm0-8.5c-2.205 0-4 1.794-4 4v.813h8V6c0-2.206-1.794-4-4-4z" fill="currentColor"></path></svg>
                                    <div className='tw-ml-[20px]'>Secure payments</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='tw-w-full tw-flex tw-flex-col tw-items-center tw-text-body-text-light-grey sourceSansPro-font tw-my-[50px] '>
                    <div className="tw-text-[16px] tw-leading-[35px] tw-w-[70%] phone:tw-w-full">
                        <EDSRichTextRenderer overflowAction={'none'} source={currentProduct && currentProduct.description} enableLatex />
                        <div className='tw-w-full tw-h-[1px] tw-bg-gray-line tw-mt-[30px]'></div>
                    </div>

                    <div className='tw-h-[1px] tw-bg-gray-line'></div>

                    {currentProduct && currentProduct.commentList_yes && currentProduct.commentList_yes.length > 0 && <div className='tw-w-[70%] tw-mt-[50px] phone:tw-mt-[30px] phone:tw-w-full phone:tw-px-[20px]'>
                        <div className='tw-flex tw-items-start phone:tw-flex-col'>
                            <div className='tw-w-[30%] phone:tw-w-full phone:tw-text-center phone:tw-pb-[50px]'>
                                <div className='tw-text-[22px] tw-mb-[30px] tw-font-bold'>Customer Reviews</div>
                                <div className='tw-text-[12px]'>Here are what our customers say.</div>
                                <div className='tw-flex tw-items-center tw-mt-[20px] phone:tw-justify-center'>
                                    <div className='tw-text-[38px]'>{currentProduct && parseFloat(currentProduct.star).toFixed(1)}</div>
                                    <div className='tw-ml-[20px]'>
                                        <div className='tw-flex tw-items-center'>
                                            {currentProduct ? new Array(currentProduct.star).fill(undefined).map(item => <StarIcon size={18} className="tw-mr-[8px]" />) : ''}
                                        </div>
                                        <div className='tw-text-[16px] tw-mt-[10px]'>Total reviews: {currentProduct && currentProduct.commentList_yes && currentProduct.commentList_yes.length}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='tw-w-[70%] tw-pl-[20px] phone:tw-w-full phone:tw-mt-[30px]'>
                                <div className='tw-w-full tw-border-b w-text-[16px] tw-flex tw-items-center'>
                                    <div onClick={() => this.handleChangeCommentTab('all')} className={classNames('tw-pb-[10px]', { 'tw-border-b tw-border-[#121b17cc] tw-font-bold': activeCommentTab === 'all' })}>{`Reviews(${currentProduct && currentProduct.commentList_yes && currentProduct.commentList_yes.length})`}</div>
                                    {currentProduct && currentProduct.commentList_no && currentProduct.commentList_no.length > 0 && <div onClick={() => this.handleChangeCommentTab('photos')} className={classNames('tw-ml-[20px] tw-pb-[10px]', { 'tw-border-b tw-border-[#121b17cc] tw-font-bold': activeCommentTab === 'photos' })}>{`With Photos(${currentProduct && currentProduct.commentList_no && currentProduct.commentList_no.length})`}</div>}
                                </div>
                                {activeCommentTab === 'all' && <div className=''>
                                    {currentProduct && currentProduct.commentList_yes && currentProduct.commentList_yes.map(item => {
                                        const imageArr = item.images && item.images !== '' ? item.images.split(',').map(i => BASE_URL + i) : [];
                                        return <div className='tw-border-b tw-py-[32px] '>
                                            <div className='tw-text-[14px] tw-font-bold tw-mb-[20px]'>{item.email}</div>
                                            <div className='tw-mb-[10px] tw-flex tw-items-center'>{item.star ? new Array(item.star).fill(undefined).map(i => <StarIcon size={18} className="tw-mr-[8px]" />) : ''}</div>
                                            <div><EDSRichTextRenderer overflowAction={'none'} source={item.content} enableLatex /></div>
                                            {imageArr.length > 0 && <div className='tw-flex tw-items-start tw-mt-[20px] tw-flex-wrap tw-mb-[10px]'>{imageArr.map(img => {
                                                return <div className='tw-w-[150px] tw-h-[150px] phone:tw-w-[80px] phone:tw-h-[80px] tw-mr-[20px] tw-rounded-[20px]' onClick={() => this.handlePreviewImg(img)} style={{ backgroundImage: `url(${img})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                            })}</div>}
                                        </div>
                                    })}

                                </div>}

                                {activeCommentTab === 'photos' && <div className='tw-py-[32px] tw-border-b'>
                                    {currentProduct && currentProduct.commentList_no && currentProduct.commentList_no.map(item => {
                                        const imageArr = item.images && item.images !== '' ? item.images.split(',').map(i => BASE_URL + i) : [];
                                        return <div>
                                            <div className='tw-text-[14px] tw-font-bold tw-mb-[20px]'>{item.email}</div>
                                            <div className='tw-mb-[10px] tw-flex tw-items-center'>{item.star ? new Array(item.star).fill(undefined).map(i => <StarIcon size={18} className="tw-mr-[8px]" />) : ''}</div>
                                            <div><EDSRichTextRenderer overflowAction={'none'} source={item.content} enableLatex /></div>
                                            {imageArr.length > 0 && <div className='tw-flex tw-items-start tw-mt-[20px] tw-flex-wrap tw-mb-[10px]'>{imageArr.map(img => {
                                                return <div className='tw-w-[150px] tw-h-[150px] phone:tw-w-[80px] phone:tw-h-[80px] tw-mr-[20px] tw-rounded-[20px]' onClick={() => this.handlePreviewImg(img)} style={{ backgroundImage: `url(${img})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                            })}</div>}
                                        </div>
                                    })}
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>
                <div className='tw-w-[1240px] phone:tw-w-full phone:tw-px-[15px] tw-px-[68px] tw-mx-auto tw-h-auto phone:tw-h-auto sourceSansPro-font tw-relative'>
                    {formatProductTagList && formatProductTagList.map((tag, index) => {
                        return (<React.Fragment>
                            <div className='tw-w-full playfair-font tw-text-[32px] tw-mb-[15px]'>{tag.title}</div>
                            <div className='tw-w-full tw-h-auto tw-mb-[132px] phone:tw-mb-[50px]'>
                                <div className='tw-w-full tw-h-full tw-flex tw-justify-between tw-items-center tw-flex-wrap'>
                                    {tag.productList && tag.productList.map((item, index) => {
                                        const img = item.image && (BASE_URL + JSON.parse(item.image)[0]);
                                        const originalPrice = parseFloat(item.originalPrice);
                                        const currentPrice = parseFloat(item.currentPrice);
                                        const discount = Math.floor(((originalPrice - currentPrice) / originalPrice) * 100);

                                        return (<div className="tw-w-[24%] phone:tw-w-[47%] tw-overflow-hidden tw-mb-[30px]" key={item.id} onClick={() => this.routeToProductDetailPage(item.id)}>
                                            <div className='tw-overflow-hidden tw-w-full tw-rounded-[15px] '>
                                                <div className='tw-w-full tw-pb-[100%] tw-relative product-img' style={{ backgroundImage: `url(${img})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} onMouseEnter={this.handleProductEnter}>
                                                    <div className='tw-absolute tw-top-[10px] tw-left-[10px] tw-font-bold tw-text-white tw-font-[20px] tw-px-[10px] tw-py-[3px] tw-rounded-[8px] tw-bg-brand-primary'>{discount}% OFF</div>
                                                </div>
                                            </div>

                                            <div className='tw-mt-[10px] tw-h-[50px] tw-line-clamp-2 tw-opacity-[0.8] tw-text-[15px]'>{item.title}</div>
                                            <div className='tw-flex tw-items-center tw-my-[5px]'>
                                                {new Array(item.star).fill(undefined).map((item, i) => {
                                                    return <StarIcon className="tw-mr-[10px]" size={20} key={i} />
                                                })}
                                            </div>
                                            <div className='tw-flex tw-justify-between tw-items-center'>
                                                <div className='tw-line-through'>${originalPrice}</div>
                                                <div className='tw-text-light-red tw-text-[20px]'>${currentPrice}</div>
                                            </div>
                                        </div>)
                                    })}
                                </div>


                            </div>
                        </React.Fragment>)
                    })}
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        currentProduct: ProductDetailSelectors.selectCurrentProduct(state),
        baseConfig: BaseConfigSelectors.selectBaseConfig(state),
        shoppingCartArr: ShoppingCartSelectors.selectShoppingCartData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(productActionCreator, dispatch),
        tagActions: bindActionCreators(tagActionCreator, dispatch),
        shoppingCartActions: bindActionCreators(shoppingCartActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
