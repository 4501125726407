import React, { Component } from 'react';
import PaymentsImg from '../../../images/payments.png';
import classNames from 'classnames';

class CustomButton extends Component {


    render() {
        const { className, color } = this.props;
        const classes = classNames(
            
            'tw-w-full tw-max-w-[500px] tw-h-[50px] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center sourceSansPro-font tw-text-[20px]',
            { "tw-bg-[#222] tw-text-white": color === "primary" },
            { "tw-border tw-border-[#222] tw-text-[#222] ": color === "white" },
            className
        );

        return (
            <div {...this.props} className={classes} >
                {this.props.children}
            </div>
        );
    }
}

export default CustomButton;